import {hostname, botScriptHostname} from "./Backend"

const endpoint = {
    login: `${hostname}/user_profile/login/`,
    fetchAllTenders: `${hostname}/tenders/all_tenders/`,
    tenderCount: `${hostname}/tenders/tender_count/`,
    manualCreateTender: `${hostname}/tenders/create_tender/`,
    addPqr: `${hostname}/tenders/add_pqrs/`,
    fetchPQRList: `${hostname}/tenders/tender_pqr`,
    editPQR: `${hostname}/tenders/edit_pqrs/`,
    triggerStatus: `${hostname}/tenders/trigger_status/`,
    tenderStat: (docID) => `${hostname}/tenders/tenders/${docID}`,
    tenderInfo: (docID) => `${hostname}/tenders/tender_info/${docID}`,
    bidderDetail: (docID) => `${hostname}/tenders/bidders/${docID}`,
    uploadTenderDocument: (docID) => `${hostname}/tenders/${docID}/upload/`,
    uploadNewBidderDocument: (docID) => `${hostname}/tenders/${docID}/create_bidder/`,
    uploadExistingBidderDocument: (docID) => `${hostname}/tenders/${docID}/update_bidder/`,
    tenderDetails: (status) => `${hostname}/tenders/tenders_by_status/?status=${status}`,
    downloadFolder: (url) => `${hostname}/tenders/download-folder/?url=${url}`,
    triggerBot: `${hostname}/tenders/trigger-bot/`,
    pqrList: `${hostname}/tenders/pqr_list/`,
    botScript: `${botScriptHostname}/bot_script`,
}

export default endpoint